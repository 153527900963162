<template>
    <div>
        <!--zh-cn-->
        <div v-if="lang == 'zh_CN'">
            <section class="wrapper">
                <h1 id="tos">什么是身份钱包？</h1>
                <p>Infinity Wallet 使用一组助记词为用户生成数字身份。数字身份下对应多链钱包就是用户的身份钱包。身份钱包有以下几点优势：</p>
                <ol>
                    <li>使用身份钱包即可管理多链资产，无需下载多个软件</li>
                    <li>通过一组身份助记词即可恢复身份对应的多链钱包，免去繁琐备份步骤</li>
                </ol>
                <p><strong>注意</strong></p>
                <ol>
                    <li>身份钱包下的不同币种密码相同，是用户创建 / 恢复身份钱包时设置的密码</li>
                    <li>除身份钱包外，其余额外创建 / 导入的钱包需单独备份助记词</li>
                    <li>退出身份钱包会同时删除用户在身份钱包外创建 / 导入的钱包，所以在退出身份钱包前请务必做好备份</li>
                </ol>
            </section>
        </div>

        <!--en-->
        <div v-else-if="lang == 'en'">
            <section class="wrapper">
                <h1 id="tos">What is an identity wallet? </h1>
                <p>Infinity Wallet uses a set of mnemonics to generate digital identities for users. The corresponding multi-chain wallet under the digital identity is the user's identity wallet. The identity wallet has the following advantages:</p>
                <ol>
                    <li>Use the identity wallet to manage multi-chain assets without downloading multiple software</li>
                    <li>The multi-chain wallet corresponding to the identity can be restored through a set of identity mnemonics, eliminating the need for cumbersome backup steps</li>
                </ol>
                <p><strong>Attention</strong></p>
                <ol>
                    <li>The passwords of different currencies under the identity wallet are the same, which is the password set when the user creates/restores the identity wallet</li>
                    <li>Except for the identity wallet, the additional created/imported wallets need to be backed up separately for mnemonics</li>
                    <li>Exiting the identity wallet will also delete the wallet created/imported by the user outside the identity wallet, so please make a backup before exiting the identity wallet</li>
                </ol>
            </section>
        </div>

        <!--zh-TW-->
        <div v-else>
            <section class="wrapper">
                <h1 id="tos">什麼是身份錢包？ </h1>
                <p>Infinity Wallet 使用一組助記詞為用戶生成數字身份。數字身份下對應多鏈錢包就是用戶的身份錢包。身份錢包有以下幾點優勢：</p>
                <ol>
                    <li>使用身份錢包即可管理多鏈資產，無需下載多個軟件</li>
                    <li>通過一組身份助記詞即可恢復身份對應的多鏈錢包，免去繁瑣備份步驟</li>
                </ol>
                <p><strong>注意</strong></p>
                <ol>
                    <li>身份錢包下的不同幣種密碼相同，是用戶創建 / 恢復身份錢包時設置的密碼</li>
                    <li>除身份錢包外，其餘額外創建 / 導入的錢包需單獨備份助記詞</li>
                    <li>退出身份錢包會同時刪除用戶在身份錢包外創建 / 導入的錢包，所以在退出身份錢包前請務必做好備份</li>
                </ol>
            </section>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Mnemonic',
        components: {},
        props: {},
        data() {
            return {
                lang: this.$route.params.lang || 'en'
            }
        },
        computed: {},
        methods: {},
        mounted() {

        },
    }
</script>

<style>

</style>
